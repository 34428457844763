<template>
  <div>
    <the-menu />

    <ModalAlert :open="modalDesist" title="ALERTA" message="¿ESTÁ SEGURO QUE DESEA DESISTIR DE SU CREDITO?"
      :accept="desist" @toggleModalDesist="modalDesist = !modalDesist"></ModalAlert>

    <div v-if="Object.keys(getUbica).length > 0">
      <Modal :openModal="modalUbica" v-on:closeModal="() => (modalUbica = false)" textClose="Cerrar"
        title="Información de Ubica">
        <UbicaInformation></UbicaInformation>
      </Modal>
    </div>

    <section class="section">
      <div class="container">
        <TheUserSearch v-if="currentStatus == 'search'" />
        <!-- error Container -->
        <div v-if="this.getError.error" class="mb-4">
          <article class="message is-warning">
            <div class="message-header">
              <p>Información</p>
            </div>
            <div class="message-body">
              {{ this.getError.errorMessage }}
            </div>
          </article>
        </div>

        <div v-if="currentStatus !== 'search'">
          <nav class="level">
            <div class="level-left"></div>
            <!-- Right side -->
            <div class="level-right">
              <a href="#" class="button is-danger is-outlined mx-1" @click.prevent="resetForm">Nueva Consulta</a>
              <a class="button is-primary is-outlined mx-1" v-if="Object.keys(getUbica).length > 0"
                @click="openModalUbica">
                Ubica
              </a>
              <a href="#" v-if="btnDesist" class="button is-primary is-outlined mx-1" @click="modalDesist = true">
                Desistir Crédito</a>
              <a href="#" v-if="btnSignature" class="button is-primary is-outlined mx-1" @click="openSignature">Generar
                link de firma</a>
              <a href="#" v-if="btnScrappingDone" class="button is-primary is-outlined mx-1" @click="DoneScrapping"
                title="Mover a confirmación de monto y plazo">siguiente</a>
            </div>
          </nav>

          <LoanDesist v-if="currentStatus == 'desist'"></LoanDesist>

          <div class="container-forms">
            <div class="wrapper" v-show="currentStatus !== 'search' &&
              currentStatus !== 'desist' &&
              !this.getError.error
              ">
              <ul>
                <li>
                  <input type="checkbox" id="basic_information" :checked="currentStatus == 'basicInformation'" disabled />
                  <label for="basic_information" id="lbl_basicInformation"
                    :class="{ completed: isStepCompleted('basicInformation') }">
                    Información Básica
                  </label>
                  <div>
                    <FormBasicInformation class="content" v-if="currentStatus == 'basicInformation'" />
                  </div>
                </li>

                <li>
                  <input type="checkbox" id="ubica" :checked="currentStatus == 'ubica'" disabled />
                  <label for="ubica" id="lbl_ubica" :class="{ completed: isStepCompleted('ubica') }">
                    Ubica
                  </label>
                  <div>
                    <FormUbica class="content" v-if="currentStatus == 'ubica'" />
                  </div>
                </li>

                <li>
                  <input type="checkbox" id="generate_otp" :checked="currentStatus == 'generateOTP'" disabled />
                  <label for="generate_otp" id="lbl_generateOTP" :class="{ completed: isStepCompleted('generateOTP') }">
                    OTP
                  </label>
                  <div>
                    <FormOTP class="content" v-if="currentStatus == 'generateOTP'" />
                  </div>
                </li>

                <li>
                  <input type="checkbox" id="personal_information" :checked="currentStatus == 'personalInformation'"
                    disabled />
                  <label for="personal_information" id="lbl_personalInformation" :class="{
                    completed: isStepCompleted('personalInformation'),
                    selectable: isStepCompleted('personalInformation')
                  }" @click="showForm('personalInformation')">
                    Información Personal
                  </label>
                  <div>
                    <FormPersonalInformation class="content" v-if="currentStatus == 'personalInformation'" />
                  </div>
                </li>

                <li>
                  <input type="checkbox" id="residential_information" :checked="currentStatus == 'residentialInformation'"
                    disabled />
                  <label for="residential_information" id="lbl_residentialInformation" :class="{
                    completed: isStepCompleted('residentialInformation'),
                    selectable: isStepCompleted('residentialInformation')
                  }" @click="showForm('residentialInformation')">
                    Información Residencial
                  </label>
                  <div>
                    <FormResidentialInformation class="content" v-if="currentStatus == 'residentialInformation'" />
                  </div>
                </li>
                <li>
                  <input type="checkbox" id="laboral_information" :checked="currentStatus == 'laboralInformation'"
                    disabled />
                  <label for="laboral_information" id="lbl_laboralInformation" :class="{
                    completed: isStepCompleted('laboralInformation'),
                    selectable: isStepCompleted('laboralInformation')
                  }" @click="showForm('laboralInformation')">
                    Referencia Laboral
                  </label>
                  <div>
                    <FormLaboralInformation class="content" v-if="currentStatus == 'laboralInformation'" />
                  </div>
                </li>
                <li>
                  <input type="checkbox" id="scraping" :checked="currentStatus == 'scraping'" disabled />
                  <label for="scraping" id="lbl_scraping" :class="{
                    completed: isStepCompleted('scraping'),
                    selectable: isStepCompleted('scraping')
                  }" @click="showForm('scraping')">
                    Scraping
                  </label>
                  <div>
                    <Scraping class="content" v-if="currentStatus == 'scraping'"></Scraping>
                  </div>
                </li>

                <li>
                  <input type="checkbox" id="simulator" :checked="currentStatus == 'simulator'" disabled />
                  <label for="simulator" id="lbl_simulator" :class="{
                    completed: isStepCompleted('simulator'),
                    selectable: isStepCompleted('simulator')
                  }" @click="showForm('simulator')">
                    Monto y Plazo
                  </label>
                  <div>
                    <Simulator class="content" v-if="currentStatus == 'simulator'"></Simulator>
                  </div>
                </li>

                <li>
                  <input type="checkbox" id="identity_validation" :checked="currentStatus == 'identityValidation'"
                    disabled />
                  <label for="identity_validation" id="lbl_identityValidation" :class="{
                    completed: isStepCompleted('identityValidation'),
                    selectable: isStepCompleted('identityValidation')
                  }" @click="showForm('identityValidation')">
                    Validación de identidad
                  </label>
                  <div>
                    <FormElectronicId class="content" v-if="currentStatus == 'identityValidation'" />
                  </div>
                </li>
                <li>
                  <input type="checkbox" id="bank_information" :checked="currentStatus == 'bankInformation'" disabled />
                  <label for="bank_information" id="lbl_bankInformation"
                    :class="{ completed: isStepCompleted('bankInformation') }">
                    Datos Bancarios
                  </label>
                  <div>
                    <FormBankInfo class="content" v-if="currentStatus == 'bankInformation'" />
                  </div>
                </li>

                <li>
                  <input type="checkbox" id="disbursement_method" :checked="currentStatus == 'disbursementMethod'"
                    disabled />
                  <label for="disbursement_method" id="lbl_disbursementMethod" :class="{
                    completed: isStepCompleted('disbursementMethod')
                  }">
                    Método de desembolso
                  </label>
                  <div>
                    <FormDisbursementMethod class="content" v-if="currentStatus == 'disbursementMethod'" />
                  </div>
                </li>

                <li>
                  <input type="checkbox" id="approved_awaiting_signature"
                    :checked="currentStatus == 'approvedAwaitingSignature'" disabled />
                  <label for="approved_awaiting_signature" id="lbl_approvedAwaitingSignature" :class="{
                    completed: isStepCompleted('approvedAwaitingSignature')
                  }">
                    Firma
                  </label>
                  <div>
                    <FormSignature class="content" v-if="currentStatus == 'approvedAwaitingSignature'" />
                  </div>
                </li>

                <li v-if="currentStatus == 'pre-disbursement'">
                  <input type="checkbox" id="pre_disbursement" :checked="currentStatus == 'pre-disbursement'" disabled />
                  <label for="pre_disbursement" id="lbl_pre-disbursement" :class="{
                    completed: isStepCompleted('pre-disbursement')
                  }">
                    Predesembolso Automático
                  </label>
                  <div>
                    <PreDisbursement class="content" v-if="currentStatus == 'pre-disbursement'" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import TheMenu from "@/components/TheMenu"
import TheUserSearch from "@/components/user/TheUserSearch"
import LoanDesist from "@/components/LoanDesist"
import FormBasicInformation from "@/components/user/FormBasicInformation"
import FormUbica from "@/components/user/FormUbica"
import FormSignature from "@/components/user/FormSignature"
import FormOTP from "@/components/user/FormOTP"
import FormPersonalInformation from "@/components/user/FormPersonalInformation"
import FormResidentialInformation from "@/components/user/FormResidentialInformation"
import FormLaboralInformation from "@/components/user/FormLaboralInformation"
import FormElectronicId from "@/components/user/FormElectronicId"
import FormBankInfo from "@/components/user/FormBankInfo"
import FormDisbursementMethod from "@/components/user/FormDisbursementMethod"
import Simulator from "@/components/simulator"
import UbicaInformation from "@/components/UbicaInformation"
import PreDisbursement from "@/components/user/FormPredisbursement"
import Scraping from "@/components/user/FormScraping"
//UI
import ModalAlert from "@/components/UI/ModalAlert"
import Modal from "@/components/UI/Modal"

export default {
  data() {
    return {
      errorMessage: "",
      modalDesist: false,
      modalUbica: false
    }
  },
  async mounted() {
    this.cleanData()
    await this.getSelectorForm()
    await this.getDepartments()
    await this.getWorkingtypeForm()
  },
  components: {
    TheMenu,
    TheUserSearch,
    LoanDesist,
    FormBasicInformation,
    FormUbica,
    FormSignature,
    FormOTP,
    FormPersonalInformation,
    FormResidentialInformation,
    FormLaboralInformation,
    FormElectronicId,
    FormBankInfo,
    Simulator,
    ModalAlert,
    Modal,
    UbicaInformation,
    FormDisbursementMethod,
    PreDisbursement,
    Scraping
  },
  methods: {
    async desist() {
      try {
        let loanActive = this.getloanActive
        await this.postLoanDesist(loanActive.id)
        this.modalDesist = false
        this.cleanData()
      } catch (err) {
        this.$notyf.error(err.message)
        this.modalDesist = false
      }
    },
    openSignature() {
      this.completeFormStep("bankInformation")
      this.$scrollTo(`#lbl_approvedAwaitingSignature`)
    },
    resetForm() {
      this.cleanData()
    },
    async DoneScrapping() {
      try {
        let loan = this.getLoadScrapping
        if (loan === undefined) {
          throw new Error("Error en el estado de credito.")
        }
        await this.postLoanDoneScrapping(loan.id)
        this.setError({
          errorStatus: false,
          errorMessage: ""
        })
        this.initialForm()
        await this.getClient({
          value: this.getDocumentNumber,
          method: "id"
        })
      } catch (err) {
        this.$notyf.error(err.message)
      }
    },
    async showForm(step_clicked) {
      if (this.enabledClick && this.isStepCompleted(step_clicked)) {
        let basicInfo = this.getBasicInfo

        await this.ubicaUser({
          documentNumber: basicInfo.cc,
          surname: basicInfo.surname
        })
        if (step_clicked == "personalInformation") {
          this.enabledFormStep([
            "personalInformation",
            "residentialInformation",
            "laboralInformation"
          ])
        }
        if (step_clicked == "residentialInformation") {
          this.enabledFormStep([
            "residentialInformation",
            "laboralInformation"
          ])
        }
        if (step_clicked == "laboralInformation") {
          this.enabledFormStep(["laboralInformation"])
        }
        if (step_clicked == "simulator") {
          this.enabledFormStep(["simulator"])
        }
        if (step_clicked == "identityValidation") {
          this.enabledFormStep(["identityValidation"])
        }
        this.$scrollTo(`#lbl_${step_clicked}`)
      }
    },
    openModalUbica() {
      this.modalUbica = true
    },
    ...mapActions({
      cleanData: "user/cleanData",
      getSelectorForm: "user/getSelectorForm",
      getWorkingtypeForm: "user/getWorkingtypeForm",
      getDepartments: "user/getDepartments",
      ubicaUser: "user/getUbica",
      postLoanDesist: "user/postLoanDesist",
      postLoanDoneScrapping: "user/postLoanDoneScrapping",
      getClient: "user/getClient"
    }),
    ...mapMutations({
      enabledFormStep: "user/enabledFormStep",
      completeFormStep: "user/completeFormStep",
      setError: "form/setError",
      initialForm: "user/initialForm"
    })
  },
  computed: {
    btnDesist() {
      let londActive = this.getloanActive
      let error = this.isError
      let id_inactive = [500, 400, 300, 203]
      if (
        londActive &&
        (!error || [101].includes(londActive.status.id)) &&
        !id_inactive.includes(londActive.status.id)
      ) {
        return true
      } else {
        return false
      }
    },
    btnSignature() {
      let londActive = this.getloanActive
      let error = this.isError
      let id_inactive = [201, 200]
      if (
        londActive &&
        !error &&
        id_inactive.includes(londActive.status.id) &&
        this.currentStatus === "bankInformation"
      ) {
        return true
      } else {
        return false
      }
    },
    btnScrappingDone() {
      return this.getLoadScrapping ? true : false
    },
    ...mapGetters({
      isError: "form/isError",
      getError: "form/getError",
      getBasicInfo: "user/getBasicInfo",
      currentStatus: "user/currentStatus",
      isStepCompleted: "user/isStepCompleted",
      enabledClick: "user/enabledClick",
      getloanActive: "user/getloanActive",
      getUbica: "user/getUbica",
      getLoadScrapping: "user/getLoadScrapping",
      getDocumentNumber: "user/getDocumentNumber"
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/style.scss";

.message-header {
  border-radius: 0px;
}

.wrapper {
  width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

label {
  display: block;
  padding: 10px;
  border: 1px solid #fff;
  border-bottom: none;
  font-weight: bold;
  background: rgb(216, 216, 216);
}

label.allowed_clic {
  cursor: pointer;
}

.selectable {
  cursor: pointer !important;
}

label:hover,
label.completed {
  background: $primary;
  color: $white;
}

label.last {
  border-bottom: 1px solid #fff;
}

ul li .content {
  padding: 15px;
}

input[type="checkbox"] {
  position: absolute;
  left: -9999px;
}

input[type="checkbox"]~.content {
  height: 0;
  transform: scaleY(0);
}

input[type="checkbox"]:checked~.content {
  height: 100%;
  transform-origin: top;
  transition: transform 0.2s ease-out;
  transform: scaleY(1);
}

input[type="checkbox"]:checked+label {
  background: $primary;
  color: $white;
  border-bottom: 1px solid #fff;
}
</style>
